<template>
  <div class="relative w-full">
    <swiper
      :slides-per-view="1"
      :space-between="50"
      :modules="[Pagination, Navigation, Autoplay]"
      :pagination="{ clickable: true }"
      :navigation="true"
      :grab-cursor="true"
      :speed="300"
      :autoplay="{ delay: 3000 }"
      :long-swipes-ratio="0.15"
    >
      <template v-for="(_, key) of $slots">
        <swiper-slide
          class="!flex flex-col justify-center items-center pb-[45px]"
        >
          <slot :name="key"></slot>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
</script>

<style lang="postcss" scoped>
.swiper:deep(.swiper-button-prev),
.swiper:deep(.swiper-button-next) {
  @apply md:w-[130px] h-full absolute top-0 z-10 cursor-pointer;
}
.swiper:deep(.swiper-button-prev.swiper-button-disabled),
.swiper:deep(.swiper-button-next.swiper-button-disabled) {
  @apply cursor-auto;
}
.swiper:deep(.swiper-button-prev) {
  @apply left-0;
}
.swiper:deep(.swiper-button-next) {
  @apply right-0;
}

.swiper:deep(.swiper-pagination-bullets) {
  @apply flex justify-center bottom-0;
}

.swiper:deep(.swiper-pagination-bullet) {
  @apply block bg-[#D9D9D9] opacity-30 mx-[3px] w-[6px] h-[6px] rounded-[3px] transition-[background,opacity,width] duration-300;
}

.swiper:deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  @apply bg-[#FEFEFE] opacity-100 w-[12px];
}
</style>
